import { render, staticRenderFns } from "./index.vue?vue&type=template&id=7a871b2e&scoped=true"
import script from "./index.vue?vue&type=script&lang=js"
export * from "./index.vue?vue&type=script&lang=js"
import style0 from "./index.vue?vue&type=style&index=0&id=7a871b2e&prod&lang=css"
import style1 from "./index.vue?vue&type=style&index=1&id=7a871b2e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a871b2e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Search: require('/tmp/build_13dc7a76/components/Search/Search.vue').default,TevoEventsArray: require('/tmp/build_13dc7a76/components/Tevo/EventsArray.vue').default,FooterCategoriesMenu: require('/tmp/build_13dc7a76/components/Footer/CategoriesMenu.vue').default,ToastPromoToastApplied: require('/tmp/build_13dc7a76/components/Toast/PromoToastApplied.vue').default,ToastPromoToast: require('/tmp/build_13dc7a76/components/Toast/PromoToast.vue').default})
