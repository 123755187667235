import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=4b2b422a&scoped=true"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=4b2b422a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4b2b422a",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Search: require('/tmp/build_13dc7a76/components/Search/Search.vue').default})
