import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _54cc50ce = () => interopDefault(import('../pages/app/index.vue' /* webpackChunkName: "pages/app/index" */))
const _701d6d12 = () => interopDefault(import('../pages/cities/index.vue' /* webpackChunkName: "pages/cities/index" */))
const _20123f18 = () => interopDefault(import('../pages/contacto/index.vue' /* webpackChunkName: "pages/contacto/index" */))
const _ae287f88 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6eac6bda = () => interopDefault(import('../pages/finish_purchase/index.vue' /* webpackChunkName: "pages/finish_purchase/index" */))
const _7224f4c9 = () => interopDefault(import('../pages/nearme/index.vue' /* webpackChunkName: "pages/nearme/index" */))
const _ea712790 = () => interopDefault(import('../pages/oxxo_order/index.vue' /* webpackChunkName: "pages/oxxo_order/index" */))
const _4f3acc75 = () => interopDefault(import('../pages/oxxo_reference/index.vue' /* webpackChunkName: "pages/oxxo_reference/index" */))
const _4bbbecb1 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _3914b656 = () => interopDefault(import('../pages/spotify_auth/index.vue' /* webpackChunkName: "pages/spotify_auth/index" */))
const _4337b134 = () => interopDefault(import('../pages/subscription/index.vue' /* webpackChunkName: "pages/subscription/index" */))
const _18794bd5 = () => interopDefault(import('../pages/legal/license.vue' /* webpackChunkName: "pages/legal/license" */))
const _6c4a9ae5 = () => interopDefault(import('../pages/legal/privacy_policy.vue' /* webpackChunkName: "pages/legal/privacy_policy" */))
const _05b7cc21 = () => interopDefault(import('../pages/legal/terms_of_service.vue' /* webpackChunkName: "pages/legal/terms_of_service" */))
const _1e27a9aa = () => interopDefault(import('../pages/news/coronavirus.vue' /* webpackChunkName: "pages/news/coronavirus" */))
const _3cca4650 = () => interopDefault(import('../pages/subscription/benefits.vue' /* webpackChunkName: "pages/subscription/benefits" */))
const _2f6af662 = () => interopDefault(import('../pages/subscription/checkout.vue' /* webpackChunkName: "pages/subscription/checkout" */))
const _3442785e = () => interopDefault(import('../pages/subscription/confirmation.vue' /* webpackChunkName: "pages/subscription/confirmation" */))
const _ef3c34ca = () => interopDefault(import('../pages/subscription/terms.vue' /* webpackChunkName: "pages/subscription/terms" */))
const _7591f58a = () => interopDefault(import('../pages/user/events/index.vue' /* webpackChunkName: "pages/user/events/index" */))
const _35b8c98e = () => interopDefault(import('../pages/user/faves/index.vue' /* webpackChunkName: "pages/user/faves/index" */))
const _7184c870 = () => interopDefault(import('../pages/user/payment-methods/index.vue' /* webpackChunkName: "pages/user/payment-methods/index" */))
const _45eb82d8 = () => interopDefault(import('../pages/user/settings/index.vue' /* webpackChunkName: "pages/user/settings/index" */))
const _7109f00e = () => interopDefault(import('../pages/user/subscription/index.vue' /* webpackChunkName: "pages/user/subscription/index" */))
const _b744142a = () => interopDefault(import('../pages/auth/pop/google/index.vue' /* webpackChunkName: "pages/auth/pop/google/index" */))
const _4242b5a9 = () => interopDefault(import('../pages/user/payment-methods/subscription_payment_methods.vue' /* webpackChunkName: "pages/user/payment-methods/subscription_payment_methods" */))
const _bbbce480 = () => interopDefault(import('../pages/email/verification/_email/_userWebId/_token/index.vue' /* webpackChunkName: "pages/email/verification/_email/_userWebId/_token/index" */))
const _36c96fa6 = () => interopDefault(import('../pages/subscription/cancel/_userWebId/_subscriptionId/_token/index.vue' /* webpackChunkName: "pages/subscription/cancel/_userWebId/_subscriptionId/_token/index" */))
const _6f624a2c = () => interopDefault(import('../pages/book/_search/index.vue' /* webpackChunkName: "pages/book/_search/index" */))
const _12536e85 = () => interopDefault(import('../pages/checkout/_ticket_group_id/index.vue' /* webpackChunkName: "pages/checkout/_ticket_group_id/index" */))
const _0ce2fddd = () => interopDefault(import('../pages/reset-password/_token/index.vue' /* webpackChunkName: "pages/reset-password/_token/index" */))
const _64e2b03e = () => interopDefault(import('../pages/results/_name/index.vue' /* webpackChunkName: "pages/results/_name/index" */))
const _90f0f456 = () => interopDefault(import('../pages/e/_name/_id.vue' /* webpackChunkName: "pages/e/_name/_id" */))
const _434d1b28 = () => interopDefault(import('../pages/book/_search/_venue_date/_event_id/index.vue' /* webpackChunkName: "pages/book/_search/_venue_date/_event_id/index" */))
const _b4018f84 = () => interopDefault(import('../pages/cities/_parent/_category_city/_city.vue' /* webpackChunkName: "pages/cities/_parent/_category_city/_city" */))
const _1ebfd744 = () => interopDefault(import('../pages/get_promo/_name/_firstName/_lastName/_email/index.vue' /* webpackChunkName: "pages/get_promo/_name/_firstName/_lastName/_email/index" */))
const _3f04f5d4 = () => interopDefault(import('../pages/_parent/_category_city/_city.vue' /* webpackChunkName: "pages/_parent/_category_city/_city" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/app",
    component: _54cc50ce,
    name: "app___en___default"
  }, {
    path: "/cities",
    component: _701d6d12,
    name: "cities___en___default"
  }, {
    path: "/contacto",
    component: _20123f18,
    name: "contacto___en___default"
  }, {
    path: "/en",
    component: _ae287f88,
    name: "index___en"
  }, {
    path: "/es",
    component: _ae287f88,
    name: "index___es"
  }, {
    path: "/finish_purchase",
    component: _6eac6bda,
    name: "finish_purchase___en___default"
  }, {
    path: "/nearme",
    component: _7224f4c9,
    name: "nearme___en___default"
  }, {
    path: "/oxxo_order",
    component: _ea712790,
    name: "oxxo_order___en___default"
  }, {
    path: "/oxxo_reference",
    component: _4f3acc75,
    name: "oxxo_reference___en___default"
  }, {
    path: "/search",
    component: _4bbbecb1,
    name: "search___en___default"
  }, {
    path: "/spotify_auth",
    component: _3914b656,
    name: "spotify_auth___en___default"
  }, {
    path: "/subscription",
    component: _4337b134,
    name: "subscription___en___default"
  }, {
    path: "/en/app",
    component: _54cc50ce,
    name: "app___en"
  }, {
    path: "/en/cities",
    component: _701d6d12,
    name: "cities___en"
  }, {
    path: "/en/contacto",
    component: _20123f18,
    name: "contacto___en"
  }, {
    path: "/en/finish_purchase",
    component: _6eac6bda,
    name: "finish_purchase___en"
  }, {
    path: "/en/nearme",
    component: _7224f4c9,
    name: "nearme___en"
  }, {
    path: "/en/oxxo_order",
    component: _ea712790,
    name: "oxxo_order___en"
  }, {
    path: "/en/oxxo_reference",
    component: _4f3acc75,
    name: "oxxo_reference___en"
  }, {
    path: "/en/search",
    component: _4bbbecb1,
    name: "search___en"
  }, {
    path: "/en/spotify_auth",
    component: _3914b656,
    name: "spotify_auth___en"
  }, {
    path: "/en/subscription",
    component: _4337b134,
    name: "subscription___en"
  }, {
    path: "/es/app",
    component: _54cc50ce,
    name: "app___es"
  }, {
    path: "/es/cities",
    component: _701d6d12,
    name: "cities___es"
  }, {
    path: "/es/contacto",
    component: _20123f18,
    name: "contacto___es"
  }, {
    path: "/es/finish_purchase",
    component: _6eac6bda,
    name: "finish_purchase___es"
  }, {
    path: "/es/nearme",
    component: _7224f4c9,
    name: "nearme___es"
  }, {
    path: "/es/oxxo_order",
    component: _ea712790,
    name: "oxxo_order___es"
  }, {
    path: "/es/oxxo_reference",
    component: _4f3acc75,
    name: "oxxo_reference___es"
  }, {
    path: "/es/search",
    component: _4bbbecb1,
    name: "search___es"
  }, {
    path: "/es/spotify_auth",
    component: _3914b656,
    name: "spotify_auth___es"
  }, {
    path: "/es/subscription",
    component: _4337b134,
    name: "subscription___es"
  }, {
    path: "/legal/license",
    component: _18794bd5,
    name: "legal-license___en___default"
  }, {
    path: "/legal/privacy_policy",
    component: _6c4a9ae5,
    name: "legal-privacy_policy___en___default"
  }, {
    path: "/legal/terms_of_service",
    component: _05b7cc21,
    name: "legal-terms_of_service___en___default"
  }, {
    path: "/news/coronavirus",
    component: _1e27a9aa,
    name: "news-coronavirus___en___default"
  }, {
    path: "/subscription/benefits",
    component: _3cca4650,
    name: "subscription-benefits___en___default"
  }, {
    path: "/subscription/checkout",
    component: _2f6af662,
    name: "subscription-checkout___en___default"
  }, {
    path: "/subscription/confirmation",
    component: _3442785e,
    name: "subscription-confirmation___en___default"
  }, {
    path: "/subscription/terms",
    component: _ef3c34ca,
    name: "subscription-terms___en___default"
  }, {
    path: "/user/events",
    component: _7591f58a,
    name: "user-events___en___default"
  }, {
    path: "/user/faves",
    component: _35b8c98e,
    name: "user-faves___en___default"
  }, {
    path: "/user/payment-methods",
    component: _7184c870,
    name: "user-payment-methods___en___default"
  }, {
    path: "/user/settings",
    component: _45eb82d8,
    name: "user-settings___en___default"
  }, {
    path: "/user/subscription",
    component: _7109f00e,
    name: "user-subscription___en___default"
  }, {
    path: "/auth/pop/google",
    component: _b744142a,
    name: "auth-pop-google___en___default"
  }, {
    path: "/en/legal/license",
    component: _18794bd5,
    name: "legal-license___en"
  }, {
    path: "/en/legal/privacy_policy",
    component: _6c4a9ae5,
    name: "legal-privacy_policy___en"
  }, {
    path: "/en/legal/terms_of_service",
    component: _05b7cc21,
    name: "legal-terms_of_service___en"
  }, {
    path: "/en/news/coronavirus",
    component: _1e27a9aa,
    name: "news-coronavirus___en"
  }, {
    path: "/en/subscription/benefits",
    component: _3cca4650,
    name: "subscription-benefits___en"
  }, {
    path: "/en/subscription/checkout",
    component: _2f6af662,
    name: "subscription-checkout___en"
  }, {
    path: "/en/subscription/confirmation",
    component: _3442785e,
    name: "subscription-confirmation___en"
  }, {
    path: "/en/subscription/terms",
    component: _ef3c34ca,
    name: "subscription-terms___en"
  }, {
    path: "/en/user/events",
    component: _7591f58a,
    name: "user-events___en"
  }, {
    path: "/en/user/faves",
    component: _35b8c98e,
    name: "user-faves___en"
  }, {
    path: "/en/user/payment-methods",
    component: _7184c870,
    name: "user-payment-methods___en"
  }, {
    path: "/en/user/settings",
    component: _45eb82d8,
    name: "user-settings___en"
  }, {
    path: "/en/user/subscription",
    component: _7109f00e,
    name: "user-subscription___en"
  }, {
    path: "/es/legal/license",
    component: _18794bd5,
    name: "legal-license___es"
  }, {
    path: "/es/legal/privacy_policy",
    component: _6c4a9ae5,
    name: "legal-privacy_policy___es"
  }, {
    path: "/es/legal/terms_of_service",
    component: _05b7cc21,
    name: "legal-terms_of_service___es"
  }, {
    path: "/es/news/coronavirus",
    component: _1e27a9aa,
    name: "news-coronavirus___es"
  }, {
    path: "/es/subscription/benefits",
    component: _3cca4650,
    name: "subscription-benefits___es"
  }, {
    path: "/es/subscription/checkout",
    component: _2f6af662,
    name: "subscription-checkout___es"
  }, {
    path: "/es/subscription/confirmation",
    component: _3442785e,
    name: "subscription-confirmation___es"
  }, {
    path: "/es/subscription/terms",
    component: _ef3c34ca,
    name: "subscription-terms___es"
  }, {
    path: "/es/user/events",
    component: _7591f58a,
    name: "user-events___es"
  }, {
    path: "/es/user/faves",
    component: _35b8c98e,
    name: "user-faves___es"
  }, {
    path: "/es/user/payment-methods",
    component: _7184c870,
    name: "user-payment-methods___es"
  }, {
    path: "/es/user/settings",
    component: _45eb82d8,
    name: "user-settings___es"
  }, {
    path: "/es/user/subscription",
    component: _7109f00e,
    name: "user-subscription___es"
  }, {
    path: "/user/payment-methods/subscription_payment_methods",
    component: _4242b5a9,
    name: "user-payment-methods-subscription_payment_methods___en___default"
  }, {
    path: "/en/auth/pop/google",
    component: _b744142a,
    name: "auth-pop-google___en"
  }, {
    path: "/en/user/payment-methods/subscription_payment_methods",
    component: _4242b5a9,
    name: "user-payment-methods-subscription_payment_methods___en"
  }, {
    path: "/es/auth/pop/google",
    component: _b744142a,
    name: "auth-pop-google___es"
  }, {
    path: "/es/user/payment-methods/subscription_payment_methods",
    component: _4242b5a9,
    name: "user-payment-methods-subscription_payment_methods___es"
  }, {
    path: "/",
    component: _ae287f88,
    name: "index___en___default"
  }, {
    path: "/en/email/verification/:email?/:userWebId?/:token",
    component: _bbbce480,
    name: "email-verification-email-userWebId-token___en"
  }, {
    path: "/en/subscription/cancel/:userWebId?/:subscriptionId?/:token",
    component: _36c96fa6,
    name: "subscription-cancel-userWebId-subscriptionId-token___en"
  }, {
    path: "/es/email/verification/:email?/:userWebId?/:token",
    component: _bbbce480,
    name: "email-verification-email-userWebId-token___es"
  }, {
    path: "/es/subscription/cancel/:userWebId?/:subscriptionId?/:token",
    component: _36c96fa6,
    name: "subscription-cancel-userWebId-subscriptionId-token___es"
  }, {
    path: "/en/book/:search",
    component: _6f624a2c,
    name: "book-search___en"
  }, {
    path: "/en/checkout/:ticket_group_id",
    component: _12536e85,
    name: "checkout-ticket_group_id___en"
  }, {
    path: "/en/reset-password/:token",
    component: _0ce2fddd,
    name: "reset-password-token___en"
  }, {
    path: "/en/results/:name",
    component: _64e2b03e,
    name: "results-name___en"
  }, {
    path: "/es/book/:search",
    component: _6f624a2c,
    name: "book-search___es"
  }, {
    path: "/es/checkout/:ticket_group_id",
    component: _12536e85,
    name: "checkout-ticket_group_id___es"
  }, {
    path: "/es/reset-password/:token",
    component: _0ce2fddd,
    name: "reset-password-token___es"
  }, {
    path: "/es/results/:name",
    component: _64e2b03e,
    name: "results-name___es"
  }, {
    path: "/en/e/:name?/:id?",
    component: _90f0f456,
    name: "e-name-id___en"
  }, {
    path: "/es/e/:name?/:id?",
    component: _90f0f456,
    name: "e-name-id___es"
  }, {
    path: "/email/verification/:email?/:userWebId?/:token",
    component: _bbbce480,
    name: "email-verification-email-userWebId-token___en___default"
  }, {
    path: "/en/book/:search?/:venue_date/:event_id",
    component: _434d1b28,
    name: "book-search-venue_date-event_id___en"
  }, {
    path: "/en/cities/:parent/:category_city?/:city?",
    component: _b4018f84,
    name: "cities-parent-category_city-city___en"
  }, {
    path: "/es/book/:search?/:venue_date/:event_id",
    component: _434d1b28,
    name: "book-search-venue_date-event_id___es"
  }, {
    path: "/es/cities/:parent/:category_city?/:city?",
    component: _b4018f84,
    name: "cities-parent-category_city-city___es"
  }, {
    path: "/subscription/cancel/:userWebId?/:subscriptionId?/:token",
    component: _36c96fa6,
    name: "subscription-cancel-userWebId-subscriptionId-token___en___default"
  }, {
    path: "/en/get_promo/:name?/:firstName?/:lastName?/:email",
    component: _1ebfd744,
    name: "get_promo-name-firstName-lastName-email___en"
  }, {
    path: "/es/get_promo/:name?/:firstName?/:lastName?/:email",
    component: _1ebfd744,
    name: "get_promo-name-firstName-lastName-email___es"
  }, {
    path: "/book/:search",
    component: _6f624a2c,
    name: "book-search___en___default"
  }, {
    path: "/checkout/:ticket_group_id",
    component: _12536e85,
    name: "checkout-ticket_group_id___en___default"
  }, {
    path: "/reset-password/:token",
    component: _0ce2fddd,
    name: "reset-password-token___en___default"
  }, {
    path: "/results/:name",
    component: _64e2b03e,
    name: "results-name___en___default"
  }, {
    path: "/e/:name?/:id?",
    component: _90f0f456,
    name: "e-name-id___en___default"
  }, {
    path: "/book/:search?/:venue_date/:event_id",
    component: _434d1b28,
    name: "book-search-venue_date-event_id___en___default"
  }, {
    path: "/cities/:parent/:category_city?/:city?",
    component: _b4018f84,
    name: "cities-parent-category_city-city___en___default"
  }, {
    path: "/en/:parent/:category_city?/:city?",
    component: _3f04f5d4,
    name: "parent-category_city-city___en"
  }, {
    path: "/es/:parent/:category_city?/:city?",
    component: _3f04f5d4,
    name: "parent-category_city-city___es"
  }, {
    path: "/get_promo/:name?/:firstName?/:lastName?/:email",
    component: _1ebfd744,
    name: "get_promo-name-firstName-lastName-email___en___default"
  }, {
    path: "/:parent/:category_city?/:city?",
    component: _3f04f5d4,
    name: "parent-category_city-city___en___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
